import { getLocaleUrl, imageloader } from '@/helpers/index';
import IcRibbon from '@/public/icons/ic-new-ribbon.svg';
import useWindowDimensions from 'hooks/useWindowDimensions';
import _, { get, uniqueId } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import { memo, useEffect, useRef, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { getDestinations } from 'services';
import { SwiperSlide } from 'swiper/react';
import SwiperCarousel from './SwiperCarousel';
import dynamic from 'next/dynamic';

const DestinationLoading = dynamic(() =>
  import('../loading/DestinationLoading')
);

function DestinationsCarousel({ locale, dataDestination }) {
  const destinationBlockRef = useRef();

  const { isMobile } = useWindowDimensions();
  const [gridDestinations, setGridDestinations] = useState([]);

  const getOldDestinations = async () => {
    try {
      const result = await getDestinations(locale);
      if (get(result, 'data.status') == 1) {
        const oldDestinations = get(result, 'data.articles', []);

        return oldDestinations.map((item) => ({
          ...item,
          country_name: get(item, 'country_name'),
          country_slug: get(item, 'country_slug'),
          city_name: get(item, 'city_name'),
          city_slug: get(item, 'city_slug'),
          image: get(item, 'image.webp', get(item, 'image_thumbnail.webp')),
          isNew: false,
          slug: null,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRenderDestinationSlide = (data) => (
    <div key={uniqueId()} className="city">
      {!data.isNew ? (
        <Link
          href={getLocaleUrl(
            locale,
            `category/travel-guide/${
              data.city_slug ? data.city_slug : data.country.slug
            }`
          )}
        >
          <div className="city-parent">
            <div className="city-wrapper">
              <div className="city-thumb">
                {data.isNew && <div className="city-thumb-new">NEW!</div>}
                {get(data, 'image') && (
                  <Image
                    src={get(data, 'image')}
                    loader={imageloader}
                    layout="fill"
                    objectFit="cover"
                    alt={get(data, 'alt_text') || 'city-thumb'}
                  />
                )}
              </div>
              <a>
                <div className="city-detail">
                  <div className="city-name">
                    {!data.isNew
                      ? get(data, 'city_name')
                      : data.city_name
                          .replace(/-/g, ' ')
                          .replace(/\b\w/g, (match) => match.toUpperCase())}
                  </div>
                  <div className="city-country">
                    {get(data, 'country_name')}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </Link>
      ) : (
        <Link href={getLocaleUrl(locale, `destination/${data.slug}`)}>
          <div className="city-parent">
            <div className="city-wrapper">
              {data.isNew && (
                <div className="city-new-ribbon">
                  <Image src={IcRibbon} layout="fill" alt="ic-new-ribbon" />
                </div>
              )}
              <div className="city-thumb">
                {get(data, 'image') && (
                  <Image
                    src={get(data, 'image')}
                    loader={imageloader}
                    layout="fill"
                    objectFit="cover"
                    alt={get(data, 'alt_text') || 'city-thumb'}
                  />
                )}
              </div>
              <a>
                <div className="city-detail">
                  <div className="city-name">
                    {!data.isNew
                      ? get(data, 'city_name')
                      : data.city_name
                          .replace(/-/g, ' ')
                          .replace(/\b\w/g, (match) => match.toUpperCase())}
                  </div>
                  <div className="city-country">
                    {get(data, 'country_name')}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </Link>
      )}
    </div>
  );

  useEffect(() => {
    const observer = new IntersectionObserver(async (entries) => {
      const entry = entries[0];
      const isVisible = entry.isIntersecting;

      if (isVisible) {
        let destinations = [];
        const newDestinations = [];

        get(dataDestination, 'destination', []).forEach((item) => {
          if (get(item, 'title')) {
            const destinationItem = {
              country_name: get(item, 'country.name'),
              country_slug: get(item, 'country.slug'),
              city_name: get(item, 'country.name'),
              city_slug: get(item, 'country.slug'),
              image: get(item, 'image.url'),
              alt_text: get(item, 'image.alt_text'),
              isNew: true,
              slug: get(item, 'slug'),
            };

            if (get(item, 'city.id', 0)) {
              destinationItem['city_name'] = get(item, 'city.name');
              destinationItem['city_slug'] = get(item, 'city.slug');
            } else if (get(item, 'region.id', 0)) {
              destinationItem['city_name'] = get(item, 'region.name');
              destinationItem['city_slug'] = get(item, 'region.slug');
            }

            destinations.push(destinationItem);
          }
        });

        if (get(dataDestination, 'old_destination', false)) {
          const chunkSize = 6;
          const oldDestinations = await getOldDestinations();
          destinations = [...destinations, ...oldDestinations];

          for (let i = 0; i < destinations.length; i += chunkSize) {
            const chunk = destinations.slice(i, i + chunkSize);
            newDestinations.push(chunk);
          }

          setGridDestinations(newDestinations);
        }
        observer.disconnect();
      }
    });

    destinationBlockRef.current &&
      observer?.observe(destinationBlockRef.current, {
        root: null, // Use the viewport as the root
        rootMargin: '0px',
        threshold: 1.0,
      });

    return () => observer.disconnect();
  }, [dataDestination]);

  return (
    <div ref={destinationBlockRef} className="destinations-block">
      <div className="destinations-block__title">
        <h1 className="title">Destinations</h1>
        <Link href={getLocaleUrl(locale, 'destination/travel-guide')}>
          <a>
            <div className="view-all">
              {isMobile ? 'View All' : 'View All Destinations'} <ChevronRight />
            </div>
          </a>
        </Link>
      </div>
      {gridDestinations.length > 0 ? (
        <div className="destinations-block__carousel">
          <SwiperCarousel
            spaceBetween={32}
            slidesPerView={1}
            isShowNavigation={true}
            totalItems={gridDestinations.length}
          >
            {_.map(gridDestinations, (destinationSlide = []) => (
              <SwiperSlide key={uniqueId()}>
                <div className="destination-slide">
                  {_.map(destinationSlide, (data) =>
                    handleRenderDestinationSlide(data)
                  )}
                </div>
              </SwiperSlide>
            ))}
          </SwiperCarousel>
        </div>
      ) : (
        <DestinationLoading />
      )}
    </div>
  );
}

export default memo(DestinationsCarousel);
